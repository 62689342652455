<template>
  <v-container>
    <v-row>
      <v-col cols="7">
        <v-card>
          <v-sheet class="pa-2 mb-n2">
            <v-text-field v-model="search" flat dense hide-details color="warning" append-icon="mdi-magnify"> </v-text-field>
          </v-sheet>
          <v-card-text>
            <v-treeview
              dense
              hoverable
              activatable
              selectable
              open-on-click
              color="default"
              transition
              selection-type="independent"
              return-object
              :items="menu"
              item-key="OptID"
              item-text="OptName"
              item-children="SecOptionChild"
              v-model="usoCurrent"
              :search="search"
              @update:active="changeOptionActive"
              :open-all="false"
            >
              <template v-slot:prepend="{ item }">
                <v-icon v-text="item.OptIcon" small right> </v-icon>
              </template>
            </v-treeview>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="5">
        <s-option-permission :UserOption="optionActive"></s-option-permission>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import _sUserOption from "@/services/Security/UserOptionService.js";
import _sOption from "@/services/Security/OptionService.js";
import sOptionPermission from "./OptionMenu/OptionPermission.vue";

export default {
  components: { sOptionPermission },
  props: {
    UsrID: {
      type: Number,
      default: 0,
    },
  },
  data: () => {
    return {
      search: "",
      menu: [],
      optionActive: {},
      usoCurrent: [],
      usoOrigin: [],
      usoIDCurrent: -1,
    };
  },
  mounted() {
    this.initialize();
  },
  computed: {},
  methods: {
    initialize() {
      _sOption.list(1, this.$fun.getUserID()).then((r) => {
        this.menu = r.data;
      });
    },
    changeOptionActive(actives) {
      if (actives.length > 0) {
        if (actives[0].TypeOption == 2 && actives[0].IsLoad == undefined) {
          _sUserOption.get(this.UsrID, actives[0].OptID, this.$fun.getUserID()).then((r) => {
            if (r.data == null) {
              actives[0].UoaCurrent = [];
              actives[0].UoaOrigin = [];
              actives[0].UolCurrent = [];
              actives[0].UolOrigin = [];
              actives[0].UopCurrent = [];
              actives[0].UopOrigin = [];
              actives[0].UosCurrent = [];
              actives[0].UosOrigin = [];
              actives[0].UotCurrent = [];
              actives[0].UotOrigin = [];
            } else {
              actives[0].UoaCurrent = r.data.SecUserOptionArea;
              actives[0].UoaOrigin = r.data.SecUserOptionArea;
              actives[0].UolCurrent = r.data.SecUserOptionLevel;
              actives[0].UolOrigin = r.data.SecUserOptionLevel;
              actives[0].UopCurrent = r.data.SecUserOptionPayroll;
              actives[0].UopOrigin = r.data.SecUserOptionPayroll;
              actives[0].UosCurrent = r.data.SecUserOptionSpecialPermission;
              actives[0].UosOrigin = r.data.SecUserOptionSpecialPermission;
              actives[0].UotCurrent = r.data.SecUserOptionTransaction;
              actives[0].UotOrigin = r.data.SecUserOptionTransaction;
            }
            actives[0].IsLoad = true;
            actives[0].IsLoadDefinition = false;

            this.optionActive = actives[0];
          });
        } else this.optionActive = actives[0];
      } else this.optionActive = {};
    },
  },
  watch: {
    UsrID(value) {
      if (value > 0)
        _sUserOption.list(value, this.$fun.getUserID()).then((r) => {
          this.usoCurrent = r.data;
          this.usoOrigin = r.data;
        });
      else {
        this.usoCurrent = [];
        this.usoOrigin = [];
      }
    },
    usoCurrent(val) {
      let id = -1;
      let current = Object.assign([], val);

      current.forEach((o) => {
        let tuso = this.usoOrigin.find((x) => x.OptID == o.OptID);

        if (tuso == null || tuso == undefined) {
          id--;
          o.UsoID = id;
        } else o.UsoID = tuso.UsoID;

        o.UsrID = this.UsrID;
        o.SecStatus = 1;
      });
      let remove = _.differenceBy(this.usoOrigin, current, "OptID");
      remove.forEach((o) => {
        o.SecStatus = 0;
      });
      this.$emit("input", current.concat(remove));
    },
  },
};
</script>
