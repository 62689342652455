<template>
  <v-row>
    <v-col :disabled="disabled" class="mt-0 pt-0">
      <v-data-table
        dense
        :headers="dtTransaction.headers"
        :items="dtTransaction.item"
        v-model="dtTransaction.model"
        :disable-sort="true"
        hide-default-footer
        item-key="TypeOperation"
        class="elevation-1 mb-5"
        show-select
      >
      </v-data-table>

      <v-data-table
        dense
        :headers="dtLevel.headers"
        :items="dtLevel.item"
        v-model="dtLevel.model"
        :disable-sort="true"
        hide-default-footer
        class="elevation-1 mb-5"
        item-key="TypeLevel"
        show-select
        single-select
      >
      </v-data-table>
      <!-- -->
      <v-data-table
        dense
        v-if="isPayroll"
        :headers="dtPayroll.headers"
        :items="dtPayroll.item"
        v-model="dtPayroll.model"
        :disable-sort="true"
        hide-default-footer
        class="elevation-1 mb-5"
        item-key="TypePayroll"
        show-select
        height="200"
      >
      </v-data-table>
      <!---->

      <v-data-table
        dense
        v-if="isArea"
        :headers="dtArea.headers"
        :items="dtArea.item"
        v-model="dtArea.model"
        :disable-sort="true"
        disable-pagination
        hide-default-footer
        class="elevation-1 mb-5"
        item-key="AreID"
        show-select
        height="200"
      >
      </v-data-table>
      <v-data-table
        dense
        :headers="dtSpecialPermission.headers"
        :items="dtSpecialPermission.item"
        v-model="dtSpecialPermission.model"
        :disable-sort="true"
        hide-default-footer
        item-key="OspID"
        class="elevation-1 mb-5"
        show-select
      >
      </v-data-table>
    </v-col>
  </v-row>
</template>
<script>
import _sOption from "@/services/Security/OptionService.js";
import _sDefinition from "@/services/General/DefinitionService.js";
import _sGeneric from "@/services/General/GenericService.js";
export default {
  components: {},
  props: {
    UserOption: {
      type: Object,
      default: {
        OptID: 0,
        SecUserOptionArea: [],
        SecUserOptionLevel: [],
        SecUserOptionPayroll: [],
        SecUserOptionSpecialPermission: [],
        SecUserOptionTransaction: [],
      },
    },
  },
  data: () => {
    return {
      UolIDCurrent: -1,
      dtLevel: {
        headers: [{ text: "NIVELES", value: "DedDescription" }],
        definition: [],
        item: [],
        model: [],
      },
      UoaIDCurrent: -1,
      dtArea: {
        headers: [{ text: "AREAS", value: "GenDescription" }],
        item: [],
        model: [],
      },
      UopIDCurrent: -1,
      dtPayroll: {
        headers: [{ text: "PLANILLAS", value: "DedDescription" }],
        item: [],
        model: [],
      },
      UotIDCurrent: -1,
      dtTransaction: {
        headers: [{ text: "TRANSACCIONES", value: "DedDescription" }],
        definition: [],
        item: [],
        model: [],
      },
      UosIDCurrent: -1,
      dtSpecialPermission: {
        headers: [{ text: "PERMISOS ESPECIALES", value: "OspDescription" }],
        item: [],
        model: [],
      },
    };
  },
  mounted() {
    this.initialize();
  },
  computed: {
    disabled() {
      return this.UserOption == {};
    },
    isPayroll() {
      return this.dtLevel.model.length > 0 ? this.dtLevel.model[0].TypeLevel == this.$const.TypeLevel.PorPlanilla : false;
    },
    isArea() {
      return this.dtLevel.model.length > 0 ? this.dtLevel.model[0].TypeLevel == this.$const.TypeLevel.PorArea : false;
    },
  },
  methods: {
    initialize() {
      var ID = this.$fun.getUserID();
      _sDefinition.definitiongroup(1038, 0, ID).then((r) => {
        this.dtLevel.definition = r.data;
      });
      _sDefinition.definitiongroup(1024, 0, ID).then((r) => {
        this.dtTransaction.definition = r.data;
      });
      _sDefinition.definitiongroup(1060, 0, ID).then((r) => {
        r.data.forEach((e) => {
          e.TypePayroll = e.DedValue;
        });

        this.dtPayroll.item = r.data;
      });
      _sGeneric.searcharea("", 3, ID).then((r) => {
        r.data.forEach((e) => {
          e.AreID = e.GenID;
        });

        this.dtArea.item = r.data;
      });
    },
    assignUserOption(value) {
      this.dtLevel.item = Object.assign([], value.LevelDef);
      this.dtTransaction.item = Object.assign([], value.TransactionDef);
      this.dtSpecialPermission.item = Object.assign([], value.SpecialPermissionDef);
      //
      this.dtLevel.model = Object.assign([], value.UolCurrent);
      this.dtTransaction.model = Object.assign([], value.UotCurrent);
      this.dtArea.model = Object.assign([], value.UoaCurrent);
      this.dtPayroll.model = Object.assign([], value.UopCurrent);
      this.dtSpecialPermission.model = Object.assign([], value.UosCurrent);
    },
  },
  watch: {
    UserOption(value) {
      if (value.OptID != undefined) {
        if (!value.IsLoadDefinition) {
          _sOption.get(value.OptID, this.$fun.getUserID()).then((r) => {
            let def;

            r.data.SecOptionLevel.forEach((o, i) => {
              def = this.dtLevel.definition.find((e) => e.DedValue == o.TypeLevel);
              if (def != null) o.DedDescription = def.DedDescription;
            });
            r.data.SecOptionTransaction.forEach((o, i) => {
              def = this.dtTransaction.definition.find((e) => e.DedValue == o.TypeOperation);
              if (def != null) o.DedDescription = def.DedDescription;
            });

            value.IsLoadDefinition = true;

            value.LevelDef = r.data.SecOptionLevel.filter((x) => x.DedDescription);
            value.TransactionDef = r.data.SecOptionTransaction.filter((x) => x.DedDescription);
            value.SpecialPermissionDef = r.data.SecOptionSpecialPermission;

            this.assignUserOption(value);
          });
        } else this.assignUserOption(value);
      } else this.assignUserOption({});
    },
    "dtTransaction.model"(value) {
      this.UserOption.UotCurrent = value;
      let current = Object.assign([], value);

      current.forEach((o) => {
        if (o.UotID == undefined || o.UotID <= 0) {
          this.UotIDCurrent--;
          o.UotID = this.UotIDCurrent;
        }
        o.SecStatus = 1;
      });

      let remove = _.differenceBy(this.UserOption.UotOrigin, current, "TypeOperation");
      remove.forEach((o) => {
        o.SecStatus = 0;
      });

      this.UserOption.SecUserOptionTransaction = current.concat(remove);
    },
    "dtArea.model"(value) {
      this.UserOption.UoaCurrent = value;
      let current = Object.assign([], value);

      current.forEach((o) => {
        if (o.UoaID == undefined || o.UoaID <= 0) {
          this.UoaIDCurrent--;
          o.UoaID = this.UoaIDCurrent;
        }
        o.SecStatus = 1;
      });

      let remove = _.differenceBy(this.UserOption.UoaOrigin, current, "AreID");
      remove.forEach((o) => {
        o.SecStatus = 0;
      });

      this.UserOption.SecUserOptionArea = current.concat(remove);
    },
    "dtPayroll.model"(value) {
      this.UserOption.UopCurrent = value;
      let current = Object.assign([], value);

      current.forEach((o) => {
        if (o.UopID == undefined || o.UopID <= 0) {
          this.UopIDCurrent--;
          o.UopID = this.UopIDCurrent;
        }
        o.SecStatus = 1;
      });

      let remove = _.differenceBy(this.UserOption.UopOrigin, current, "TypePayroll");
      remove.forEach((o) => {
        o.SecStatus = 0;
      });

      this.UserOption.SecUserOptionPayroll = current.concat(remove);
    },
    "dtLevel.model"(value) {
      this.UserOption.UolCurrent = value;
      let current = Object.assign([], value);

      current.forEach((o) => {
        if (o.UolID == undefined || o.UolID <= 0) {
          this.UolIDCurrent--;
          o.UolID = this.UolIDCurrent;
        }
        o.SecStatus = 1;
      });

      let remove = _.differenceBy(this.UserOption.UolOrigin, current, "TypeLevel");
      remove.forEach((o) => {
        o.SecStatus = 0;
      });

      this.UserOption.SecUserOptionLevel = current.concat(remove);
    },
    "dtSpecialPermission.model"(value) {
      this.UserOption.UosCurrent = value;
      let current = Object.assign([], value);

      current.forEach((o) => {
        if (o.UosID == undefined || o.UosID <= 0) {
          this.UosIDCurrent;
          o.UosID = this.UosIDCurrent;
        }
        o.SecStatus = 1;
      });

      let remove = _.differenceBy(this.UserOption.UosOrigin, current, "OspID");
      remove.forEach((o) => {
        o.SecStatus = 0;
      });

      this.UserOption.SecUserOptionSpecialPermission = current.concat(remove);
    },
  },
};
</script>
