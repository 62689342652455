import Service from "../Service";

const resource = "useroption/";

export default {
  get(usrID, optID, requestID) {
    return Service.post(
      resource,
      {},
      {
        params: { usrID: usrID, optID: optID, requestID: requestID },
      }
    );
  },
  list(usrID, requestID) {
    return Service.post(
      resource + "list",
      {},
      {
        params: { usrID: usrID, requestID: requestID },
      }
    );
  },
  save(uso, requestID) {
    return Service.post(resource + "save", uso, {
      params: { requestID: requestID },
    });
  },
};
