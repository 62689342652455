<template>
  <v-row>
    <v-col sm="12" md="5">
      <s-user @input="changeUserSelected" />
    </v-col>
    <v-col sm="12" md="7" :disabled="user == {}">
      <v-card>
        <v-container>
          <v-row>
            <v-col cols="12" md="5" offset-md="7" class="s-col-form">
              <v-btn small block left color="primary" dark @click="saveOptions" hide-details>
                <v-icon left small>fas fa-save</v-icon> Actualizar Permisos
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="px-0">
          <s-option :UsrID="user.UsrID" @input="updateUserOption"> </s-option>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import _sUserOption from "@/services/Security/UserOptionService.js";
import _sOption from "@/services/Security/OptionService.js";
import sUser from "./UserOption/User.vue";
import sOption from "./UserOption/OptionMenu.vue";

export default {
  components: { sUser, sOption },
  data: () => {
    return {
      user: {},
      userOption: [],
    };
  },
  mounted() {},
  computed: {},
  methods: {
    changeUserSelected(user) {
      this.user = user;
    },
    updateUserOption(value) {
      this.userOption = value;
    },
    saveOptions() {
      let id = this.user.UsrID;
      _sUserOption.save(this.userOption, this.$fun.getUserID()).then((r) => {
        this.user.UsrID = 0;
        this.user.UsrID = id;
        this.$fun.alert("Permisos actualizados correctamente.", "success");
      });
    },
  },
  watch: {},
};
</script>
